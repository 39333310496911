@keyframes slide {
    from {
        transform: translateX(0);
    }
    to {
        transform: translateX(-100%);
    }
}

.words {
    overflow: hidden;
    white-space: nowrap;
    padding-bottom: 40px;
    color: #eddcc0;
}

.words-slide {
    display: inline-block;
    animation: 30s slide infinite linear;
}

.words-slide p {
    height: 50px;
    font-size: 200px;
    padding-bottom: 40px;
}

.grad {
    /* height: 300px; */
    background: linear-gradient(258deg, #47ac6a, #4d93d1);
    background-size: 200% 200%;

    animation: AnimationName 30s ease infinite;
    position: relative;

    /* -webkit-mask-image: linear-gradient(
        to top,
        rgba(0, 0, 0, 1),
        rgba(0, 0, 0, 0)
    );
    -webkit-mask-size: 100% 100%; */
}
.gradyuh:before {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    weidth: 20px;
    background: red;
    content: ""; /* Need this */
    z-index: 2;
}

@keyframes AnimationName {
    0% {
        background-position: 0% 51%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 51%;
    }
}
